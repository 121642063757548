
import { defineComponent, PropType } from "vue";
import DoubleRange from "../../../shared/components/DoubleRange.vue";
import { FiltersData, FilterOptions, FilterOptionRangeValue } from "@kunsten/core";
import { isEqual } from "lodash-es";

export default defineComponent({
    name: "SearchFilterPeriod",
    components: { DoubleRange },
    props: {
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        filterOptions: {
            type: Object as PropType<FilterOptions>,
            required: true,
        },
        filterNameStart: {
            type: String,
            required: true,
        },
        filterNameEnd: {
            type: String,
            required: true,
        },
        label: String,
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: this.filters as FiltersData,
            minLock: false,
            maxLock: false,
        };
    },
    computed: {
        filterNameStartAfter() {
            return this.filterNameStart + "[after]";
        },
        filterNameEndBefore() {
            return this.filterNameEnd + "[before]";
        },
        options() {
            let min = 0;
            let max = 0;
            const startDates = this.filterOptions[this.filterNameStart] as FilterOptionRangeValue;
            const endDates = this.filterOptions[this.filterNameEnd] as FilterOptionRangeValue;
            if (startDates && endDates) {
                const startDateMin = Number(startDates.min?.slice(0, 4));
                const startDateMax = Number(startDates.max?.slice(0, 4));
                const endDateMin = Number(endDates.min?.slice(0, 4));
                const endDateMax = Number(endDates.max?.slice(0, 4));

                min = startDateMin < endDateMin ? startDateMin : endDateMin;
                max = startDateMax > endDateMax ? startDateMax : endDateMax;
            }
            return { min, max };
        },
        value_str() {
            return JSON.stringify(this.value);
        },
    },
    watch: {
        value_str: {
            deep: true,
            handler(newValue_str: string, oldValue_str: string) {
                // workaround needed to compare new value to old one
                let newValue = JSON.parse(newValue_str);
                let oldValue = JSON.parse(oldValue_str);

                // Specs are getting complicated here, but anyway we want to
                // remove lock when ...

                // 1. Removed from SearchOptions startDate
                if (!newValue[this.filterNameStartAfter] && oldValue[this.filterNameStartAfter]) {
                    this.minLock = false;
                    this.maxLock = false;
                    return;
                }

                // 2. Removed from SearchOptions endDate
                if (!newValue[this.filterNameEndBefore] && oldValue[this.filterNameEndBefore]) {
                    this.minLock = false;
                    this.maxLock = false;
                    return;
                }

                delete newValue[this.filterNameStartAfter];
                delete newValue[this.filterNameEndBefore];
                delete oldValue[this.filterNameStartAfter];
                delete oldValue[this.filterNameEndBefore];

                // 3. Changed filter other than date
                if (!isEqual(oldValue, newValue)) {
                    this.minLock = false;
                    this.maxLock = false;
                }

                // 4. Otherwise we want to keep min and max value locked (no action)
            },
        },
    },
});
