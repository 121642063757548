import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b0b01b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-filter-checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Checkbox, {
      modelValue: _ctx.value[_ctx.filterName],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setValue($event))),
      label: _ctx.label,
      id: "search-filters-on-display-checkbox",
      hidden: _ctx.hidden
    }, null, 8, ["modelValue", "label", "hidden"])
  ]))
}