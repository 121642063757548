
import { defineComponent, PropType } from "vue";
import SearchInput from "./SearchInput.vue";
import PresavedSearches from "../../../shared/components/PresavedSearches.vue";
import { FilterOptions, FiltersData, filtersFromQuery } from "@kunsten/core";

export default defineComponent({
    name: "SearchTop",
    components: { SearchInput, PresavedSearches },
    props: {
        filterOptions: {
            type: Object as PropType<FilterOptions>,
            required: true,
        },
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        loading: Boolean,
    },
    data() {
        return {
            value: this.filters as FiltersData,
        };
    },
    methods: {
        forceFiltersUpdate(query: string) {
            const queryFilters = {} as Record<string, string | string[]>;
            const params = query.split("?")[1];
            if (params) {
                Object.keys(this.value).forEach((key) => {
                    delete this.value[key];
                });

                params.split("&").forEach((param) => {
                    const key = param.split("=")[0];
                    const value = param.split("=")[1].replace(/\+/g, " ");
                    queryFilters[key] = value;
                });

                const filters = filtersFromQuery(queryFilters);

                Object.keys(filters).forEach((key) => {
                    this.value[key] = filters[key];
                });
            }
        },
    },
});
