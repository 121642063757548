import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOption = _resolveComponent("SearchOption")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["options", { 'pb-5': _ctx.getSelectedFilters.length }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSelectedFilters, (filter) => {
      return (_openBlock(), _createBlock(_component_SearchOption, {
        onRemove: ($event: any) => (_ctx.onRemove(filter)),
        key: filter.value,
        hidden: _ctx.hidden
      }, {
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(filter.label), 1)
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(filter.value), 1)
        ]),
        _: 2
      }, 1032, ["onRemove", "hidden"]))
    }), 128))
  ], 2))
}