
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "SearchInputAutoComplete",
    props: {
        options: {
            type: Array as PropType<{ value: string }[]>,
            required: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        activeIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            active: this.activeIndex,
        };
    },
    mounted() {
        document.addEventListener("keydown", this.onKeyboardDown);
        window.addEventListener("keydown", this.onWindowKeyboardDown);
    },
    unmounted() {
        document.removeEventListener("keydown", this.onKeyboardDown);
        window.removeEventListener("keydown", this.onWindowKeyboardDown);
    },
    methods: {
        complete() {
            if (this.options.length) {
                const autoCompletedValue = this.options[this.active]?.value;
                if (autoCompletedValue) this.$emit("autoComplete", autoCompletedValue);
            }
        },
        onKeyboardDown(e: KeyboardEvent) {
            if (!this.open) return;

            if (e.key === "Home") {
                this.setActiveIndex(0);
            }

            if (e.key === "End") {
                this.setActiveIndex(this.options.length - 1);
            }

            if (e.key === "ArrowUp") {
                if (this.active > 0) this.setActiveIndex(this.active - 1);
            }

            if (e.key === "ArrowDown") {
                if (this.active < this.options.length - 1) this.setActiveIndex(this.active + 1);
            }

            if (e.key === "Enter") {
                e.preventDefault();
                this.complete();
            }
        },
        onWindowKeyboardDown(e: KeyboardEvent) {
            if (this.open && (e.code === "ArrowUp" || e.code === "ArrowDown")) {
                e.preventDefault();
            }
        },
        activeOptionId(index: number) {
            return `active-auto-complete-option-${index}`;
        },
        setActiveIndex(index: number) {
            this.active = index;
            this.$emit("update:activeIndex", this.active);
        },
    },
    watch: {
        activeIndex(value: number) {
            this.active = value;
        },
    },
});
