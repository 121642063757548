<template>
    <div class="search-page mb-6">
        <SearchTop class="py-5" :filters="filters" :filterOptions="filterOptions" :loading="loading" />
        <div class="page-container">
            <SearchResultsHeader
                :filters="filters"
                :loading="loading"
                :totalItems="totalItems"
                v-model:open="open"
                v-model:displayMode="displayMode"
            />
            <SearchFilters :filters="filters" :filterOptions="filterOptions" v-model:open="open" />
            <SearchResults :results="results" :displayMode="displayMode" />
            <SearchLoadMore @load="loadMore()" :loading="loading" :canLoadMore="canLoadMore" />
        </div>
    </div>
</template>

<script>
import {
    useMemoryCachedSearch,
    filtersFromQuery,
    syncFiltersAndPageWithQuery,
    useDocumentTitle,
    useFilterOptions,
} from "@kunsten/core";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import SearchFilters from "../shared/SearchFilters.vue";
import SearchResults from "../shared/SearchResults.vue";
import SearchResultsHeader from "../shared/SearchResultsHeader.vue";
import SearchTop from "../shared/SearchTop.vue";
import SearchLoadMore from "../shared/SearchLoadMore.vue";

export default defineComponent({
    name: "Search",
    components: { SearchFilters, SearchResults, SearchResultsHeader, SearchTop, SearchLoadMore },
    setup() {
        const route = useRoute();
        const displayMode = ref("four-column");
        const open = ref(false);

        const { locale, t } = useI18n();

        useDocumentTitle(t("general.base-title"), t("navigation.artworks"));

        const { filterOptions } = useFilterOptions(locale);

        const { filters, totalItems, results, loading, page, canLoadMore, loadMore } = useMemoryCachedSearch(
            "SEARCH_CACHE_KEY",
            {
                language: locale,
                initialFilters: filtersFromQuery(route.query),
            }
        );

        syncFiltersAndPageWithQuery(filters, page);

        return { filters, filterOptions, totalItems, results, open, loading, displayMode, page, canLoadMore, loadMore };
    },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";

.search-page {
    background-color: $kun-dark;
    @include expand-min-height();
    .search-results-header {
        @include tablet {
            padding-top: 1.5rem;
        }
    }
}
</style>
