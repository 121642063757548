
import { defineComponent, PropType } from "vue";
import MultiSelect from "../../../shared/components/MultiSelect.vue";
import { FiltersData, FilterOptions, SelectFilterKeys, isFilterOptionTermValue } from "@kunsten/core";

export default defineComponent({
    name: "SearchFilterMultiSelect",
    components: { MultiSelect },
    props: {
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        filterOptions: {
            type: Object as PropType<FilterOptions>,
            required: true,
        },
        filterName: {
            type: String as PropType<SelectFilterKeys>,
            required: true,
        },
        single: {
            type: Boolean,
            default: false,
        },
        search: {
            type: Boolean,
            default: false,
        },
        label: String,
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: this.filters as FiltersData,
        };
    },
    computed: {
        options() {
            const options = this.filterOptions[this.filterName];
            if (isFilterOptionTermValue(options)) {
                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                return options.terms.map((o) => {
                    return {
                        value: o.value,
                        text: o.value,
                        hitCount: o.count,
                    };
                });
            }
            return [];
        },
    },
    watch: {
        value(newValue: FiltersData) {
            this.$emit("update:filters", newValue);
        },
    },
});
