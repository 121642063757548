import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cdf188a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-top" }
const _hoisted_2 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_PresavedSearches = _resolveComponent("PresavedSearches")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchInput, {
        filters: _ctx.value,
        "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        filterOptions: _ctx.filterOptions,
        filterName: "q",
        optionName: "autoComplete",
        loading: _ctx.loading
      }, null, 8, ["filters", "filterOptions", "loading"]),
      _createVNode(_component_PresavedSearches, {
        onRedirect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.forceFiltersUpdate($event)))
      })
    ])
  ]))
}