import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04b25292"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-results-header" }
const _hoisted_2 = ["disabled", "aria-expanded"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchResultsCount = _resolveComponent("SearchResultsCount")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_SearchDisplayMode = _resolveComponent("SearchDisplayMode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchResultsCount, { count: _ctx.totalItems }, null, 8, ["count"]),
    _createElementVNode("button", {
      disabled: _ctx.loading,
      ref: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onOpenClick())),
      "aria-expanded": _ctx.open,
      class: "btn gossamer py-4 px-5"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.open ? _ctx.$t("search.filters.hide") : _ctx.$t("search.filters.show")) + "  " + _toDisplayString(_ctx.numOfFilters ? `(${_ctx.numOfFilters})` : ""), 1),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Spinner, {
            key: 0,
            size: "sm"
          }))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createElementVNode("i", {
              class: _normalizeClass(["fas", _ctx.open ? 'fa-minus' : 'fa-plus'])
            }, null, 2)
          ]))
    ], 8, _hoisted_2),
    _createVNode(_component_SearchDisplayMode, {
      displayMode: _ctx.displayMode,
      onChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:displayMode', $event)))
    }, null, 8, ["displayMode"])
  ]))
}