
import { DefineComponent, defineComponent, PropType } from "vue";
import { FilterOptions, FiltersData, isFilterOptionTermValue } from "@kunsten/core";
import { debounce } from "lodash-es";

import SearchInputAutoComplete from "./SearchInputAutoComplete.vue";
import Spinner from "../../../shared/components/Spinner.vue";

export default defineComponent({
    name: "SearchInput",
    components: { SearchInputAutoComplete, Spinner },
    props: {
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        filterName: {
            type: String,
            required: true,
        },
        filterOptions: {
            type: Object as PropType<FilterOptions>,
            required: true,
        },
        optionName: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: this.filters as FiltersData,
            focused: false,
            searchValue: "",
            activeIndex: 0,
            minLength: 3,
        };
    },
    mounted() {
        (this.$refs.input as HTMLInputElement).focus();
        document.addEventListener("keydown", this.onKeyboardDown);
        document.addEventListener("click", this.onOutsideClick);
    },
    unmounted() {
        document.removeEventListener("keydown", this.onKeyboardDown);
        document.removeEventListener("click", this.onOutsideClick);
    },
    computed: {
        options() {
            const options = this.filterOptions[this.filterName];
            if (isFilterOptionTermValue(options)) {
                // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                return options.terms
                    .map((o) => {
                        // We are not sure about the format right now
                        return {
                            value: o.value,
                        };
                    })
                    .slice(0, 5);
            }

            return [];
        },
    },
    methods: {
        onInput(value: string) {
            this.searchValue = value;
            this.activeIndex = 0;
            this.setValueDebounce();
            // when closed by escape
            if (!this.focused) this.focused = true;
        },
        completeValue(value: string) {
            this.searchValue = value;
            this.activeIndex = 0;
            this.setValue();
        },
        setValue() {
            if (this.searchValue === "" || this.searchValue?.length < this.minLength) {
                if (this.value[this.filterName]) delete this.value[this.filterName];
            } else {
                this.value[this.filterName] = this.searchValue;
            }
        },
        setValueDebounce: debounce(function (this: DefineComponent) {
            this.setValue();
        }, 500),
        onOutsideClick(ev: MouseEvent | FocusEvent) {
            if (!this.focused) return;
            const input = this.$refs.input as HTMLInputElement;
            // hide auto complete popup
            if (input && !input.contains(ev.target as Element)) this.focused = false;
        },
        onKeyboardDown(e: KeyboardEvent) {
            if (!this.focused) return;
            // hide auto complete popup
            if (e.key === "Escape") {
                this.focused = false;
            }
        },
    },
    watch: {
        filters: {
            immediate: true,
            deep: true,
            handler(value: FiltersData) {
                // When query has been removed from SearchOptions
                if (!value[this.filterName] && this.searchValue) {
                    this.searchValue = "";
                }
                // When user goes back to search - query value is extracted from cache
                if (value[this.filterName] && !this.searchValue) {
                    this.searchValue = value[this.filterName] as string;
                }
            },
        },
    },
});
