import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d7d387c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-filters-container" }
const _hoisted_2 = { class: "search-filters-row" }
const _hoisted_3 = { class: "search-filters-row" }
const _hoisted_4 = { class: "search-filters-row" }
const _hoisted_5 = { class: "search-filters-row" }
const _hoisted_6 = { class: "search-filters-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOptions = _resolveComponent("SearchOptions")!
  const _component_SearchFilterMultiSelect = _resolveComponent("SearchFilterMultiSelect")!
  const _component_SearchFilterCheckbox = _resolveComponent("SearchFilterCheckbox")!
  const _component_SearchFilterPeriod = _resolveComponent("SearchFilterPeriod")!
  const _directive_tab_index = _resolveDirective("tab-index")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search-filters", { open: _ctx.open }]),
    id: "search-page-filters",
    ref: "filtersContainer",
    style: _normalizeStyle({ maxHeight: !_ctx.open ? '0px' : _ctx.getMaxHeight() + 'px' }),
    onKeydown: _cache[9] || (_cache[9] = _withKeys(($event: any) => (_ctx.$emit('update:open', false)), ["esc"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SearchOptions, {
        filters: _ctx.filtersData,
        "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filtersData) = $event)),
        hidden: !_ctx.open
      }, null, 8, ["filters", "hidden"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SearchFilterMultiSelect, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterOptions: _ctx.filterOptions,
          filterName: "artType",
          label: _ctx.$t('search.filters.artType'),
          search: true,
          hidden: !_ctx.open
        }, null, 8, ["filters", "filterOptions", "label", "hidden"]),
        _createVNode(_component_SearchFilterMultiSelect, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterOptions: _ctx.filterOptions,
          filterName: "creatorsGenders",
          label: _ctx.$t('search.filters.gender'),
          hidden: !_ctx.open
        }, null, 8, ["filters", "filterOptions", "label", "hidden"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SearchFilterMultiSelect, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterOptions: _ctx.filterOptions,
          filterName: "creatorsNames",
          label: _ctx.$t('search.filters.artist'),
          search: true,
          hidden: !_ctx.open
        }, null, 8, ["filters", "filterOptions", "label", "hidden"]),
        _createVNode(_component_SearchFilterMultiSelect, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterOptions: _ctx.filterOptions,
          filterName: "materials",
          label: _ctx.$t('search.filters.material'),
          hidden: !_ctx.open
        }, null, 8, ["filters", "filterOptions", "label", "hidden"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SearchFilterMultiSelect, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterOptions: _ctx.filterOptions,
          filterName: "creatorsNationalities",
          label: _ctx.$t('search.filters.nationality'),
          hidden: !_ctx.open
        }, null, 8, ["filters", "filterOptions", "label", "hidden"]),
        _createVNode(_component_SearchFilterMultiSelect, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterOptions: _ctx.filterOptions,
          filterName: "technique",
          label: _ctx.$t('search.filters.technique'),
          hidden: !_ctx.open
        }, null, 8, ["filters", "filterOptions", "label", "hidden"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SearchFilterCheckbox, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterName: "onDisplay",
          label: _ctx.$t('search.filters.on-display'),
          hidden: !_ctx.open
        }, null, 8, ["filters", "label", "hidden"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_SearchFilterPeriod, {
          filters: _ctx.filtersData,
          "onUpdate:filters": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filtersData) = $event)),
          filterOptions: _ctx.filterOptions,
          filterNameStart: "productionStartDate",
          filterNameEnd: "productionEndDate",
          label: _ctx.$t('search.filters.period'),
          hidden: !_ctx.open
        }, null, 8, ["filters", "filterOptions", "label", "hidden"])
      ])
    ])
  ], 38)), [
    [_directive_tab_index, _ctx.open]
  ])
}