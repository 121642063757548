
import { defineComponent, PropType } from "vue";
import SearchResultsCount from "./SearchResultsCount.vue";
import SearchDisplayMode from "./SearchDisplayMode.vue";
import { DisplayMode } from "./search-types";
import Spinner from "../../../shared/components/Spinner.vue";
import { FiltersData } from "@kunsten/core";

export default defineComponent({
    name: "SearchResultsHeader",
    components: { SearchResultsCount, SearchDisplayMode, Spinner },
    props: {
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        totalItems: Number,
        open: Boolean,
        displayMode: String as PropType<DisplayMode>,
        loading: Boolean,
    },
    mounted() {
        document.addEventListener("click", this.onOutsideButtonAndFiltersClick);
    },
    unmounted() {
        document.removeEventListener("click", this.onOutsideButtonAndFiltersClick);
    },
    computed: {
        numOfFilters() {
            if (!this.filters) return 0;
            return Object.values(this.filters).reduce(
                (sum: number, f) => (Array.isArray(f) ? sum + f.length : sum + 1),
                -1 // There is always one filter "lang"
            );
        },
    },
    methods: {
        onOutsideButtonAndFiltersClick(ev: MouseEvent | FocusEvent) {
            if (!this.open) return;
            const button = this.$refs.button as HTMLButtonElement;
            const filters = document.querySelector("#search-page-filters");
            if (
                button &&
                !button.contains(ev.target as Element) &&
                filters &&
                !filters.contains(ev.target as Element)
            ) {
                this.$emit("update:open", false);
            }
        },
        onOpenClick() {
            this.$emit("update:open", !this.open);
        },
    },
});
