
import { defineComponent, DefineComponent } from "vue";
import { debounce } from "lodash-es";

export default defineComponent({
    name: "DoubleRange",
    props: {
        startDate: {
            type: String, // yyyy-mm-dd
            required: true,
        },
        endDate: {
            type: String, // yyyy-mm-dd
            required: true,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        minLock: {
            type: Boolean,
            default: false,
        },
        maxLock: {
            type: Boolean,
            default: false,
        },
        label: String,
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            minRange: 0,
            maxRange: 0,
            yearOne: this.startDate?.length ? Number(this.startDate.slice(0, 4)) : 0,
            yearTwo: this.endDate?.length ? Number(this.endDate.slice(0, 4)) : 0,
        };
    },
    computed: {
        lowerValue() {
            return this.yearOne > this.yearTwo ? this.yearTwo : this.yearOne;
        },
        higherValue() {
            return this.yearOne > this.yearTwo ? this.yearOne : this.yearTwo;
        },
    },
    methods: {
        setyearOne(year: string) {
            this.yearOne = Number(year);
            this.updateLineBetween();
            this.updateValueDebounce();
        },
        setyearTwo(year: string) {
            this.yearTwo = Number(year);
            this.updateLineBetween();
            this.updateValueDebounce();
        },
        updateValueDebounce: debounce(function (this: DefineComponent) {
            this.$emit("update:startDate", this.lowerValue + "-01-01");
            this.$emit("update:endDate", this.higherValue + "-12-31");
        }, 1000),
        updateLineBetween() {
            const left = ((this.lowerValue - this.minRange) / (this.maxRange - this.minRange)) * 100;
            const width = ((this.higherValue - this.lowerValue) / (this.maxRange - this.minRange)) * 100;

            if (this.$refs.between) {
                (this.$refs.between as HTMLDivElement).style.left = left.toFixed(0) + "%";
                (this.$refs.between as HTMLDivElement).style.width = width.toFixed(0) + "%";
            }
        },
    },
    mounted() {
        this.updateLineBetween();
    },
    watch: {
        min: {
            immediate: true,
            handler(value) {
                if (!this.minLock && value) {
                    this.minRange = value;
                    if (!this.yearOne) this.yearOne = this.minRange;
                    this.$emit("update:minLock", true);
                }
            },
        },
        max: {
            immediate: true,
            handler(value) {
                if (!this.maxLock && value) {
                    this.maxRange = value;
                    if (!this.yearTwo) this.yearTwo = this.maxRange;
                    this.$emit("update:maxLock", true);
                }
            },
        },
        minLock() {
            this.updateLineBetween();
        },
        maxLock() {
            this.updateLineBetween();
        },
    },
});
