
import { defineComponent, PropType } from "vue";
import SearchFilterMultiSelect from "./SearchFilterMultiSelect.vue";
import SearchFilterCheckbox from "./SearchFilterCheckbox.vue";
import SearchFilterPeriod from "./SearchFilterPeriod.vue";
import SearchOptions from "./SearchOptions.vue";
import { FiltersData, FilterOptions } from "@kunsten/core";

export default defineComponent({
    name: "SearchFilters",
    components: { SearchFilterCheckbox, SearchFilterMultiSelect, SearchFilterPeriod, SearchOptions },
    props: {
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        filterOptions: {
            type: Object as PropType<FilterOptions>,
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            filtersData: this.filters,
        };
    },
    methods: {
        getMaxHeight() {
            // height of search options new row -> 93 px
            return (this.$refs.filtersContainer as HTMLDivElement).scrollHeight + 93;
        },
    },
    watch: {
        open(value) {
            if (value) (this.$refs.filtersContainer as HTMLDivElement)?.focus();
        },
    },
});
