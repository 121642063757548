
import { defineComponent } from "vue";

export default defineComponent({
    name: "SearchOption",
    emits: ["remove"],
    props: {
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        remove() {
            this.$emit("remove");
        },
    },
});
