
import { defineComponent, PropType } from "vue";
import SearchOption from "./SearchOption.vue";
import { SelectFilterKeys, DateFilterKeys, FiltersData, SELECTS_FILTER_KEYS, DATE_FILTER_KEYS } from "@kunsten/core";

export default defineComponent({
    name: "SearchOptions",
    components: { SearchOption },
    props: {
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            filterOptions: this.filters as FiltersData,
        };
    },
    computed: {
        getSelectedFilters(): Array<{ key: SelectFilterKeys | DateFilterKeys | string; value: string }> {
            let selectedFilters = [] as Array<{
                key: SelectFilterKeys | DateFilterKeys | string;
                value: string;
                label: string;
            }>;

            SELECTS_FILTER_KEYS.forEach((key: SelectFilterKeys) => {
                if (this.filterOptions[key]?.length) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const values = this.filterOptions[key]!.map((value) => {
                        return { key, value, label: this.getLabel(key) };
                    });
                    selectedFilters = selectedFilters.concat(values);
                }
            });

            DATE_FILTER_KEYS.forEach((key: DateFilterKeys) => {
                if (this.filterOptions[key]) {
                    const dateFilter = [{ key: key, value: this.filterOptions[key], label: this.getLabel(key) }] as {
                        key: DateFilterKeys;
                        value: string;
                        label: string;
                    }[];
                    selectedFilters = selectedFilters.concat(dateFilter);
                }
            });

            if (this.filterOptions["q"]) {
                const queryFilter = [
                    {
                        key: "q",
                        value: this.filterOptions["q"],
                        label: this.$t("search.filters.phrase"),
                    },
                ];
                selectedFilters = selectedFilters.concat(queryFilter);
            }

            if (this.filterOptions["onDisplay"]) {
                const onDisplayFilter = [
                    {
                        key: "onDisplay",
                        value: this.filterOptions["onDisplay"] ? this.$t("general.yes") : this.$t("general.no"),
                        label: this.$t("search.filters.on-display"),
                    },
                ];
                selectedFilters = selectedFilters.concat(onDisplayFilter);
            }

            return selectedFilters.sort((a, b) => a.value.localeCompare(b.value));
        },
    },
    methods: {
        onRemove(obj: { key: SelectFilterKeys | DateFilterKeys | string; value: string }): void {
            if (Array.isArray(this.filterOptions[obj.key])) {
                this.filterOptions[obj.key] = this.filterOptions[obj.key as SelectFilterKeys]?.filter(
                    (v) => v !== obj.value
                );
            } else {
                delete this.filterOptions[obj.key];
            }
            this.$emit("update:filters", this.filterOptions);
        },
        getLabel(key: SelectFilterKeys | DateFilterKeys) {
            switch (key) {
                case "artType":
                    return this.$t("search.filters.artType");
                case "creatorsGenders":
                    return this.$t("search.filters.gender");
                case "creatorsNames":
                    return this.$t("search.filters.artist");
                case "creatorsNationalities":
                    return this.$t("search.filters.nationality");
                case "materials":
                    return this.$t("search.filters.material");
                case "technique":
                    return this.$t("search.filters.technique");
                default:
                    if (key.includes("productionStartDate")) return this.$t("search.filters.startDate");
                    return this.$t("search.filters.endDate");
            }
        },
    },
});
