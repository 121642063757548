import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-filter-period" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoubleRange = _resolveComponent("DoubleRange")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DoubleRange, {
      startDate: _ctx.value[_ctx.filterNameStartAfter],
      "onUpdate:startDate": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value[_ctx.filterNameStartAfter]) = $event)),
      endDate: _ctx.value[_ctx.filterNameEndBefore],
      "onUpdate:endDate": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value[_ctx.filterNameEndBefore]) = $event)),
      minLock: _ctx.minLock,
      "onUpdate:minLock": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.minLock) = $event)),
      maxLock: _ctx.maxLock,
      "onUpdate:maxLock": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.maxLock) = $event)),
      min: _ctx.options.min,
      max: _ctx.options.max,
      label: _ctx.label,
      hidden: _ctx.hidden
    }, null, 8, ["startDate", "endDate", "minLock", "maxLock", "min", "max", "label", "hidden"])
  ]))
}