import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-filter-multi-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultiSelect, {
      modelValue: _ctx.value[_ctx.filterName],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value[_ctx.filterName]) = $event)),
      options: _ctx.options,
      search: _ctx.search,
      single: _ctx.single,
      label: _ctx.label,
      placeholder: _ctx.label,
      hidden: _ctx.hidden
    }, null, 8, ["modelValue", "options", "search", "single", "label", "placeholder", "hidden"])
  ]))
}