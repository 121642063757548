import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e82b276"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-activedescendant"]
const _hoisted_2 = ["onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.open)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "search-input-auto-complete",
        "aria-activedescendant": _ctx.activeOptionId(_ctx.active)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("button", {
            key: option.value,
            class: _normalizeClass(["btn option", { active: _ctx.active === index }]),
            tabindex: "-1",
            onMouseenter: ($event: any) => (_ctx.setActiveIndex(index)),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.complete()))
          }, _toDisplayString(option.value), 43, _hoisted_2))
        }), 128))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}