
import { defineComponent, PropType } from "vue";
import Checkbox from "../../../shared/components/Checkbox.vue";
import { FiltersData } from "@kunsten/core";

export default defineComponent({
    name: "SearchFilterCheckbox",
    components: { Checkbox },
    props: {
        filters: {
            type: Object as PropType<FiltersData>,
            required: true,
        },
        filterName: {
            type: String,
            required: true,
        },
        label: String,
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: this.filters as FiltersData,
        };
    },
    methods: {
        // We do not wanna send onDisplay false as it has
        // different meaning that not sending onDisplay at all
        setValue(checkboxValue: boolean) {
            if (checkboxValue === false) {
                delete this.value[this.filterName];
            } else {
                this.value[this.filterName] = checkboxValue;
            }
        },
    },
    watch: {
        value(newValue: FiltersData) {
            this.$emit("update:filters", newValue);
        },
    },
});
